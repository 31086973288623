import { isDesktop } from "react-device-detect";

import RequiredAuth from "./common/middleware/authPermission";
import RequiredPublicAuth from './common/middleware/authPublicPermission';
import Home from "./containers/Home/Home";
import Slot from "./containers/Slot/Slot";
import Sport from "./containers/Sport/Sport";
import P2p from "./containers/P2p/P2P";
import Lottery from "./containers/Lottery/Lottery";
import Games from "./containers/Games/Games";
import LiveCasino from "./containers/LiveCasino/LiveCasino";
import PersonalInformation from "./containers/Profile/PersonalInformation/PersonalInformation";
import Certificate from "./containers/Certificate/Certificate";
import VipExclusive from "./containers/Profile/VipExclusive/VipExclusive";
import Wallet from "./containers/Profile/Wallet/Wallet";
import Promos from "./containers/Promos/Promos";
import AboutUs from "./containers/AboutUs/AboutUs";
import InfoCentre from "./containers/InfoCentre/InfoCentre";
import ResponsibleGaming from "./containers/ResponsibleGaming/ResponsibleGaming";
import Register from "./containers/Register/Register";
import RegisterSocialMedia from "./containers/Register/RegisterSocialMedia";
import ContactUs from "./containers/ContactUs/ContactUs";
import TransactionHistory from "./containers/Profile/TransactionHistory/TransactionHistory";
import BettingHistory from "./containers/Profile/BettingHistory/BettingHistory";
import Mall from "./containers/Mall/Mall";
import MallCheckout from "./containers/Mall/MallCheckout";
import MallCart from "./containers/Mall/MallCart";
import RedemptionHistory from "./containers/Profile/RedemptionHistory/RedemptionHistory";
// import MobileEventPrizeClaim from "./containers/Profile/RedemptionHistory/MobileEventPrizeClaim";
import Messages from "./containers/Profile/Messages/Messages";
import PromosDetail from "./containers/Promos/PromosDetail";
import Login from "./containers/Login/Login";
import Vip from "./containers/Vip/Vip";
import ForgetPassword from "./containers/ForgetPassword/ForgetPassword";
import Referral from "./containers/Profile/Referral/Referral";
import Feedback from "./containers/Profile/Feedback/Feedback";
import Deposit from "./containers/Profile/Deposit/Deposit";
import Withdrawal from "./containers/Profile/Withdrawal/Withdrawal";
import DefaultSlot from "./containers/Slot/DefaultSlot";
import GameLauncher from "./containers/GameLauncher/GameLauncher";
import DefaultGames from "./containers/Games/DefaultGames";
import FeedbackList from "./containers/Profile/Feedback/FeedackList";
import InnerFeedback from "./containers/Profile/Feedback/InnerFeedback";
// import InnerMessage from "./containers/Profile/Messages/InnerMessage";
import DefaultP2p from "./containers/P2p/DefaultP2p";
import DefaultLottery from "./containers/Lottery/DefaultLottery";
import MobileRegionLanguage from "./containers/RegionLanguage/MobileRegionLanguage";
// import MobileProviderGames from "./containers/MobileProviderGames/MobileProviderGames";
import PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy";

//mobile page 
import MobileProfile from "./containers/Profile/PersonalInformation/MobileProfile"
import ErrorPageMobile from "./containers/Miscellaneous/ErrorPageMobile";
import MaintenancePageMobile from "./containers/Miscellaneous/MaintenancePageMobile";
import RestrictionPageMobile from "./containers/Miscellaneous/RestrictionPageMobile";
// import Sponsor from "./containers/Sponsor/Sponsor";

import LuckySpin from "./containers/Events/LuckySpin/LuckySpin";
import BetAndEarn from "./containers/Events/BetAndEarn/BetAndEarn";
import DownloadApp from "./containers/DownloadApp/DownloadApp";
import CheckIn from "./containers/ChackIn/CheckIn";
import Leaderboard from "./containers/Leaderboard/Leaderboard";
import FifaWorldCup from "./containers/FifaWorldCup/FifaWorldCup";
import FifaQna from "./containers/FifaWorldCup/FifaQna";
import Events from "./containers/Events/Events";
import MobileLiveStreaming from "./containers/LiveStreaming/LiveStreaming";
import Bonus from "./containers/Profile/Bonus/Bonus";
import FifaPremierLeague from "./containers/FifaPremierLeague/FifaPremierLeague";
// import ChristmasEvent from "./containers/Events/ChristmasEvent/ChristmasEvent";
// import CnyLuckyDraw from "./containers/Events/CnyLuckyDraw/CnyLuckyDraw";
// import AnniLuckyDraw from "./containers/Events/Anniversary/AnniLuckyDraw";
import { detectCountry } from "./hoc/Shared/utility";
// import UefaPrediction from "./components/UefaPrediction/UefaPrediction";

let dynamicCountry = detectCountry(window.location.host);

const routes = [

    //mobile page
    { path: "/404", exact: true, name: "ErrorPageMobile", component: ErrorPageMobile, slug: "404" },
    { path: "/maintenance", exact: true, name: "MaintenancePageMobile", component: MaintenancePageMobile, slug: "maintenance" },
    { path: "/restriction", exact: true, name: "RestrictionPageMobile", component: RestrictionPageMobile, slug: "restriction" },

    //Mobile Profile
    !isDesktop && { path: "/me", exact: true, name: "MobileProfile", component: RequiredAuth(MobileProfile), slug: "me" },

    //Mobile Region and Language
    { path: "/region-language", exact: true, name: "MobileRegionLanguage", component: MobileRegionLanguage, slug: "region-language" },


    //Login
    { path: "/login", exact: true, name: "Login", component: RequiredPublicAuth(Login), slug: "login" },
    //Register
    { path: "/register", exact: true, name: "Register", component: RequiredPublicAuth(Register), slug: "register" },
    // Register Social Media
    { path: "/registerSocialMedia", exact: true, name: "Register Social Media", component: RequiredPublicAuth(RegisterSocialMedia), slug: "register-social-media" },
    //ForgetPassword
    { path: "/forget-password", exact: true, name: "ForgetPassword", component: ForgetPassword, slug: "forget-password" },
    //Verify Forgot Password
    { path: "/verify-forgot-password/:token", exact: true, name: "Verify Forgot Password", component: ForgetPassword, slug: "verify-forgot-password" },
    // Home
    { path: "/", exact: true, name: "Home", component: Home, slug: "home" },
    // Slot
    { path: "/slot", exact: true, name: "Slot", component: DefaultSlot, slug: "slot" },
    { path: "/slot/:slug", exact: true, name: "Slot", component: Slot, slug: "slot" },
    // Sport
    { path: `/online-sports-games-${dynamicCountry.label}`, exact: true, name: "Sport", component: Sport, slug: "sport" },
    // P2P
    { path: "/p2p", exact: true, name: "P2P", component: DefaultP2p, slug: "p2p" },
    { path: "/p2p/:slug", exact: true, name: "P2P", component: P2p, slug: "p2p" },
    // Lottery
    { path: "/lottery", exact: true, name: "Lottery", component: DefaultLottery, slug: "lottery" },
    { path: "/lottery/:slug", exact: true, name: "Lottery", component: Lottery, slug: "lottery" },
    // Games
    { path: "/games", exact: true, name: "Game", component: DefaultGames, slug: "games" },
    { path: "/games/:slug", exact: true, name: "Game", component: Games, slug: "games" },
    // Live Casino
    { path: `/online-live-games-${dynamicCountry.label}`, exact: true, name: "Live Casino", component: LiveCasino, slug: "casino" },
    //Promos 
    { path: "/promos", exact: true, name: "Promos", component: Promos, slug: "promos" },
    //Promos Detail
    { path: "/promos-detail/:slug", exact: true, name: "PromosDetail", component: PromosDetail, slug: "promos-detail" },

    //About Us
    { path: "/about-us", exact: true, name: "AboutUs", component: AboutUs, slug: "about-us" },
    //Info Centre
    { path: "/info-centre", exact: true, name: "InfoCentre", component: InfoCentre, slug: "info-centre" },
    //Responsible Gaming
    { path: "/responsible-gaming", exact: true, name: "ResponsibleGaming", component: ResponsibleGaming, slug: "responsible-gaming" },
    //Vip
    { path: "/vip", exact: true, name: "Vip", component: Vip, slug: "vip" },
    //Contact Us
    { path: "/contact-us", exact: true, name: "ContactUs", component: ContactUs, slug: "contact-us" },
    // Certificate
    { path: "/certificate", exact: true, name: "Certificate", component: Certificate, slug: "certificate" },

    // PersonalInformation
    { path: "/personal-information", exact: true, name: "Personal Information", component: RequiredAuth(PersonalInformation), slug: "personal-information" },
    // Vip Exclusive
    { path: "/vip-exclusive", exact: true, name: "Vip Exclusive", component: RequiredAuth(VipExclusive), slug: "vip-exclusive" },
    // Bonus
    { path: "/bonus", exact: true, name: "Bonus", component: RequiredAuth(Bonus), slug: "bonus" },
    // My Wallet
    { path: "/my-wallet", exact: true, name: "My Wallet", component: Wallet, slug: "my-wallet" },
    // Transaction History
    { path: "/transaction-history", exact: true, name: "Transaction History", component: TransactionHistory, slug: "transaction-history" },
    // Betting History
    { path: "/betting-history", exact: true, name: "Betting History", component: BettingHistory, slug: "betting-history" },
    // Redemption History
    { path: "/redemption-history", exact: true, name: "Redemption History", component: RedemptionHistory, slug: "redemption-history" },
    // process.env.REACT_APP_ENV === "staging" && ({ path: "/redemption-history/event-prize", exact: true, name: "EventPrize Add Address", component: MobileEventPrizeClaim, slug:"redemption-history/event-prize"}),
    // Mall
    { path: "/mall", exact: true, name: "Mall", component: Mall, slug: "mall" },
    { path: "/mall/checkout", exact: true, name: "Mall Checkout", component: MallCheckout, slug: "mall/checkout" },
    { path: "/mall/cart", exact: true, name: "Mall Cart", component: MallCart, slug: "mall/cart" },
    // Messages
    { path: "/messages", exact: true, name: "Messages", component: RequiredAuth(Messages), slug: "message" },
    //Inner Messages
    // { path: "/messages/inner-message", exact: true, name: "InnerMessage", component: RequiredAuth(InnerMessage), slug: "inner-message" },
    // Referral
    { path: "/referral", exact: true, name: "Referral", component: Referral, slug: "referral" },
    //Feedback
    { path: "/feedback", exact: true, name: "Feedback", component: Feedback, slug: "feedback" },
    //Feedback List
    { path: "/feedback/list", exact: true, name: "FeedbackList", component: FeedbackList, slug: "/feedback/list" },
    //Inner Feedback
    { path: "/feedback/list/inner-feedback", exact: true, name: "InnerFeedback", component: InnerFeedback, slug: "/feedback/list/inner-feedback" },


    //Deposit
    { path: "/deposit", exact: true, name: "Deposit", component: Deposit, slug: "deposit" },
    //Withdrawal
    { path: "/withdrawal", exact: true, name: "Withdrawal", component: Withdrawal, slug: "withdrawal" },
    // Game Launcher
    { path: "/game-launcher", exact: true, name: "GameLauncher", component: RequiredAuth(GameLauncher), slug: "game-launcher" },
    // Privacy Policy
    { path: "/privacy-policy", exact: true, name: "PrivacyPolicy", component: PrivacyPolicy, slug: "privacy-policy" },
    // Sponsor
    // { path: "/sponsor", exact: true, name: "Sponsor", component: Sponsor, slug: "sponsor" }, 

    // Lucky Spin
    { path: "/lucky-spin", exact: true, name: "LuckySpin", component: LuckySpin, slug: "lucky-spin" },
    // Bet N Earn
    { path: "/bet-and-earn", exact: true, name: "BetAndEarn", component: BetAndEarn, slug: "bet-and-earn" },
    // Download App
    { path: "/download-app", exact: true, name: "DownloadApp", component: DownloadApp, slug: "download-app" },
    // Check in page
    { path: "/check-in", exact: true, name: "CheckIn", component: CheckIn, slug: "check-in" },
    // LeaderBoard
    process.env.REACT_APP_ENV === "staging" && ({ path: "/leaderboard", exact: true, name: "Leaderboard", component: Leaderboard, slug: "leaderboard" }),
    // WorldCup
    process.env.REACT_APP_ENV === "staging" && ({ path: "/fifa-world-cup", exact: true, name: "FifaWorldCup", component: FifaWorldCup, slug: "fifa-world-cup" }),
    process.env.REACT_APP_ENV === "staging" && ({ path: "/fifa-qna", exact: true, name: "FifaQna", component: FifaQna, slug: "fifa-qna" }),
    // Events
    process.env.REACT_APP_ENV === "staging" && ({ path: "/events", exact: true, name: "Events", component: Events, slug: "events" }),

    //Live Stream
    process.env.REACT_APP_ENV === "staging" && ({ path: "/live-streaming", exact: true, name: "MobileLiveStreaming", component: MobileLiveStreaming, slug: "live-streaming" }),

    //Fifa Premier League
    { path: "/english-premier-league", exact: true, name: "FifaPremierLeague", component: FifaPremierLeague, slug: "english-premier-league" },

    //UEFA Prediction
    // { path: "/euro-2024", exact: true, name: "UefaPrediction", component: UefaPrediction, slug: "uefa-prediction" },
    // process.env.REACT_APP_ENV === "staging" && ({  path: "/euro-2024", exact: true, name: "UefaPrediction", component: UefaPrediction, slug: "uefa-prediction" }),

    //Christmas Event
    // { path: `/christmas-draw`, exact: true, name: "ChristmasEvent", component: ChristmasEvent, slug: "christmas-draw" },
    
    //CNY Lucky Draw Event
    // { path: "/cny-lucky-draw", exact: true, name: "CnyLuckyDraw", component: CnyLuckyDraw, slug: "cny-draw" },
    
    //Anniversary Lucky Draw
    // { path: "/anniversary-lucky-draw", exact: true, name: "AnniLuckyDraw", component: AnniLuckyDraw, slug: "anniversary-lucky-draw" },
];

export default routes;
