import React, { Component } from 'react'
import Modal from "react-modal";
import { Icon } from "@iconify/react";
import Skeleton from "react-loading-skeleton";
import Slider from 'react-slick';
import domain from "../../common/data/domain.json";
// import NewFeature from './NewFeature';
// import { isDesktop } from 'react-device-detect';

export class BackupUrlModal extends Component {
  constructor(props) {
    super(props);
    const t = this.props.t;
    this.state = {
      header: t("global.new-feature-added"),
      slideCount: 0,
    };
    this.sliderRef = React.createRef();
  }

  componentDidUpdate() {
    // const t = this.props.t;
    const slideCount = this.sliderRef.current?.innerSlider?.state?.slideCount;
    if (slideCount && slideCount !== this.state.slideCount) {
      this.setState({ slideCount });
    }
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const t = this.props.t;
    const icons = this.imageImport(require.context("../../assets/images/icons", false, /\.(gif|png|jpe?g|svg)$/));
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      // autoplay: true,
      autoplaySpeed: 3000,
      cssEase: "linear",
      beforeChange: (oldIndex, newIndex) => {
        switch (newIndex) {
          case 0:
            this.setState({ header: t("global.new-feature-added") })
            break;
          case this.state.slideCount - 1:
            this.setState({ header: t("global.cant-open-how", { attribute: process.env.REACT_APP_WEBSITE_TITLE }) })
            break;
          default:
            this.setState({ header: t("global.new-feature-added") })
            break;
        }
      }
    };

    const backupDomainActiveMY = domain?.backup_domain_active_my || '';
    const backupDomainInactiveMY = domain?.backup_domain_inactive_my || '';
    const backupDomainActiveID = domain?.backup_domain_active_id || '';
    const backupDomainInactiveID = domain?.backup_domain_inactive_id || '';

    return (

      <Modal
        isOpen={this.props.showBackupUrlModal}
        closeTimeoutMS={500}
        portalClassName={`global-modal`}
        className="modal modal-dialog modal-dialog-centered modal-pd vip-link-modal"
        ariaHideApp={false}
        onRequestClose={this.props.toggleBackupUrlModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="vip-link-modal-label">
              {this.state.header}
            </h5>
            <Icon type="button" className="btn-close text-white" icon="mdi:close" onClick={this.props.toggleBackupUrlModal} />
          </div>

          <form className="py-3" onSubmit={this.props.handleSubmit}>
            <Slider {...settings} ref={this.sliderRef}>

              {/* <NewFeature
                featureHeader={t("global.redeem-bonus-way")}
                featureDesc={t("global.redeem-bonus-way-1")}
                featureImg={isDesktop ? ["redeem-bonus1-1.webp", "redeem-bonus1-2.webp"] : ["redeem-bonus1-m-1.webp", "redeem-bonus1-m-2.webp", "redeem-bonus1-m-3.webp"]}
                linkBtn={"/personal-information"}
                {...this.props}
              />

              <NewFeature
                featureHeader={t("global.redeem-bonus-way")}
                featureDesc={t("global.redeem-bonus-way-2")}
                featureImg={isDesktop ? ["redeem-bonus2-1.webp", "redeem-bonus2-2.webp"] : ["redeem-bonus2-m-1.webp", "redeem-bonus2-m-2.webp"]}
                linkBtn={"/personal-information"}
                {...this.props}
              />

              <NewFeature
                featureHeader={t("global.whatsapp-notification-setting")}
                featureDesc={t("global.whatsapp-feature-desc")}
                featureImg={isDesktop ? ["whatsapp-notice-1.webp", "whatsapp-notice-2.webp"] : ["whatsapp-notice-m-1.webp", "whatsapp-notice-m-2.webp"]}
                linkBtn={"/messages"}
                {...this.props}
              /> */}

              <div>
                <div className="modal-body">
                  <h6>{t("global.one-click-bookmark")}</h6>
                  <img src={icons["change-link-img.svg"]} alt="change link img" />
                  <hr />

                  <h5 className="modal-title" id="vip-link-modal-label">
                    {t("global.personal-vip-domain")}
                  </h5>
                  <ul>
                    {(() => {
                      this.props.backupUrlLoading === true && <Skeleton count={4} height="100%" />;

                      if (this.props.backupUrlLoading === false) {
                        switch (this.props.currentUser.country_code) {
                          case "MY":
                            return (
                              <>
                                {this.props.topProfileData && this.props.topProfileData.topProfile.deposit_status === 1
                                ? 
                                  backupDomainActiveMY.length > 0 && backupDomainActiveMY.map((domain, idx) => (
                                    <li
                                      className={`btn btn-outline-blue ${this.props.backupUrlValue === domain && "active"}`}
                                      onClick={(e) => this.props.redirectBackupUrlHandler(e, domain)}
                                      key={idx}
                                    >
                                      <Icon icon="uil:globe" />
                                      <span>{domain}</span>
                                    </li>
                                  ))
                                : 
                                  (backupDomainInactiveMY.length > 0) && backupDomainInactiveMY.map((domain, idx) => (
                                    <li
                                      className={`btn btn-outline-blue ${this.props.backupUrlValue === domain && "active"}`}
                                      onClick={(e) => this.props.redirectBackupUrlHandler(e, domain)}
                                      key={idx}
                                    >
                                      <Icon icon="uil:globe" />
                                      <span>{domain}</span>
                                    </li>
                                  ))
                                }
                              </>
                            );
                          case "ID":
                            return (
                              <>
                                {this.props.topProfileData && this.props.topProfileData.topProfile.deposit_status === 1
                                ? 
                                  backupDomainActiveID.length > 0 && backupDomainActiveID.map((domain, idx) => (
                                    <li
                                      className={`btn btn-outline-blue ${this.props.backupUrlValue === domain && "active"}`}
                                      onClick={(e) => this.props.redirectBackupUrlHandler(e, domain)}
                                      key={idx}
                                    >
                                      <Icon icon="uil:globe" />
                                      <span>{domain}</span>
                                    </li>
                                  ))
                                : 
                                  backupDomainInactiveID.length > 0 && backupDomainInactiveID.map((domain, idx) => (
                                    <li
                                      className={`btn btn-outline-blue ${this.props.backupUrlValue === domain && "active"}`}
                                      onClick={(e) => this.props.redirectBackupUrlHandler(e, domain)}
                                      key={idx}
                                    >
                                      <Icon icon="uil:globe" />
                                      <span>{domain}</span>
                                    </li>
                                  ))
                                }
                              </>
                            );
                            break;
                          default:
                            break;
                        }
                      }
                    })()}
                  </ul>
                </div>
              </div>
            </Slider>
            {this.props.backupUrlLoading === false ? (
              <div className="modal-footer">
                {/* <button type="button" className="btn btn-gradient-blue" onClick={this.redirectBackupUrlHandler}>
                                    {t("global.confirm")}
                                </button> */}
                <div className="d-flex align-items-center w-100 px-3">
                  <input type="checkbox" id="dont-show-check" value="showModal" onChange={(e) => this.props.setDontShow(e)} />
                  <label className="ms-1" htmlFor="dont-show-check">
                    {t("global.dont-ask-again")}
                  </label>
                </div>
              </div>
            ) : (
              <Skeleton count={1} height="100%" />
            )}
          </form>

        </div>
      </Modal>

    )
  }
}

export default BackupUrlModal