import React, { Component } from 'react';
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { Icon } from '@iconify/react';
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import { isDesktop } from "react-device-detect";
import { connect } from "react-redux";
import { claimAngpau } from "../../../store/ducks/angpau/actions";

export class RayaEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            angpaoAmt: "",
            bonusStatus: false,
        };
    }
    componentDidMount() {
        document.body.classList.add('modal-open');
    }

    componentDid = () => {
        const { t } = this.props;
        Swal.fire({
            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : '',
            icon: "info",
            title: t("global.sorry"),
            text: this.props.currentEvent === "cny" ? t("cny.cny-requirement") : this.props.currentEvent === "raya" ? t("cny.raya-requirement") : this.props.currentEvent === "dewali" ? t("cny.dewali-requirement") : this.props.currentEvent === "thaipusam" && t("cny.thaipusam-requirement"),
            confirmButtonText: t("global.ok"),
            customClass: (!isDesktop) ? {
                container: "swal-mobile-container sorry",
                icon: "swal-sorry-icon",
            } : []
        });
    }



    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;

        if (nextProps.claim_angpau_result !== this.props.claim_angpau_result) {
            if (nextProps.claim_angpau_result.responseCode === 200) {
                if (nextProps.claim_angpau_result.data.depositStatus === false) {
                    Swal.fire({
                        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/fa6-solid:face-sad-tear.svg?color=%23b0b0b0">' : '',
                        icon: "info",
                        title: t("global.sorry"),
                        text: this.props.currentEvent === "cny" ? t("cny.cny-requirement") : this.props.currentEvent === "raya" ? t("cny.raya-requirement") : this.props.currentEvent === "dewali" ? t("cny.dewali-requirement") : this.props.currentEvent === "thaipusam" && t("cny.thaipusam-requirement"),
                        confirmButtonText: t("global.ok"),
                        customClass: (!isDesktop) ? {
                            container: "swal-mobile-container sorry",
                            icon: "swal-sorry-icon",
                        } : []
                    });
                } else {
                    this.setState({
                        bonusStatus: true,
                        angpaoAmt: nextProps.claim_angpau_result.data.amount
                    });
                }
            } else {
                return false;
            }
        }

        return true;
    }

    openAngpao = () => {
        let data = {};
        data.action = "click";
        this.props.claimAngpau(data);
        // this.setState({
        //   bonusStatus: true,
        // });
    };

    closeAngpao = () => {
        this.setState({
            homePageEvents: false,
        });
    };


    imageImport = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    render() {
        const { t } = this.props;
        const raya = this.imageImport(require.context("../../../assets/images/home-overlay-events/", false, /\.(png|jpe?g|svg|webp)$/));
        const angpao = this.imageImport(require.context("../../../assets/images/home-overlay-events/", false, /\.(png|jpe?g|svg|webp)$/));
        const dewali = this.imageImport(require.context("../../../assets/images/home-overlay-events/dewali", false, /\.(png|jpe?g|svg|webp)$/));

        let imageLanguage = '';
        switch (this.props.currentUser.language_code) {
            case "zh-CN":
                imageLanguage = "-cn";
                break;
            case "ms":
                imageLanguage = "-bm"
                break;
            case "id":
                imageLanguage = "-bm"
                break;
            default:
                imageLanguage = "";
                break;
        }

        return (
            <Auxiliary>
                {this.props.homePageEvents ? (
                    <section className={`ani-section ${this.props.currentEvent === "cny" ? "cny" : this.props.currentEvent === "raya" ? "raya" : this.props.currentEvent === "dewali" ? "raya dewali" : this.props.currentEvent === "thaipusam" && "raya dewali"}`}>
                        <div className='ani-content-wrapper'>
                            {this.state.bonusStatus === false ?
                                <div className={`ani-header-wrapper ${!isDesktop && 'mobile'}`}>
                                    <div className='ani-title'>
                                        {
                                            this.props.currentEvent === "raya" ?
                                                <>
                                                    <h1>{t("cny.raya-events")}</h1>
                                                    <h5>{t("cny.highest-receive")} RM168</h5>
                                                    <img className={`title-bg ${!isDesktop && 'mobile'}`} src={raya["ketupak_ribbon.png"]} alt="ketupak-ribbon" />
                                                </>
                                                :
                                                this.props.currentEvent === "dewali" ?
                                                    <>
                                                        <h1> {t("cny.dewali-events")} </h1>
                                                        <h5>{t("cny.highest-receive")} RM168</h5>
                                                    </>
                                                    :
                                                    this.props.currentEvent === "thaipusam" &&
                                                    <>
                                                        <h1>{t("cny.thaipusam-events")} </h1>
                                                        <h5>{t("cny.highest-receive")} RM168</h5>
                                                    </>
                                        }
                                    </div>
                                </div>
                                :
                                <div className={`ani-header-wrapper ${!isDesktop && 'mobile'}`}>
                                    <div className='ani-title'>
                                        {this.props.currentEvent === "cny" ? <h3>{t("cny.congratz")} <span className='fes-ani-amount'>RM{this.state.angpaoAmt}</span></h3> : ""}
                                        {this.props.currentEvent === "dewali" | this.props.currentEvent === "thaipusam" ? <h1>{t("cny.congratz")}{t("cny.received")}</h1> : ""}
                                    </div>
                                </div>
                            }
                            <div className='ani-item-wrapper'>
                                <img className={`flare-light ${!isDesktop && 'mobile'}`} src={raya["shine.png"]} alt="flare light" />
                                {this.state.bonusStatus === false ? (
                                    <>
                                        <img className={`fes-ani-asset ${!isDesktop && 'mobile'}`} src={
                                            this.props.currentEvent === "cny" ?
                                                angpao[`angpao${imageLanguage}.webp`] :
                                                this.props.currentEvent === "raya" ?
                                                    raya["ketupak.png"] :
                                                    this.props.currentEvent === "dewali" ?
                                                        dewali["dewali.png"] :
                                                        this.props.currentEvent === "thaipusam" &&
                                                        dewali["dewali.png"]
                                        }
                                            alt="angpao"
                                            onClick={this.openAngpao} />
                                    </>
                                ) :
                                    (
                                        <>
                                            {
                                                this.props.currentEvent === "cny" ?
                                                    <>
                                                        <img className={`fes-ani-asset  ${!isDesktop && 'mobile'}`} src={angpao[(this.state.angpaoAmt == "0.33") ? `033${imageLanguage}.webp` : this.state.angpaoAmt + `${imageLanguage}.webp`]} alt="angpao" />
                                                        <img className={`ani-burst ${!isDesktop && 'mobile'}`} src={angpao["coin.png"]} alt="coin burst" />
                                                        <img className={`ani-burst-one ${!isDesktop && 'mobile'}`} src={angpao["coin-1.png"]} alt="coin burst" />
                                                    </>
                                                    :
                                                    this.props.currentEvent === "raya" ?
                                                    <>
                                                        <div className={`fes-amount-wrap ${!isDesktop && 'mobile'}`}>
                                                            <h5>
                                                                {t("cny.congratz")}
                                                                <br />
                                                                {t("cny.received")}
                                                            </h5>
                                                            <p>RM<br /><span className='fes-ani-amount'>{this.state.angpaoAmt}</span></p>
                                                        </div>
                                                        <img className={`fes-ani-asset ${!isDesktop && 'mobile'}`} src={raya["ketupak-open.png"]} alt="ketupak open" />
                                                        <img className={`ani-burst ${!isDesktop && 'mobile'}`} src={raya["star-1.png"]} alt="star burst" />
                                                        <img className={`ani-burst-one ${!isDesktop && 'mobile'}`} src={raya["star-2.png"]} alt="star burst" /> </> 
                                                        :
                                                this.props.currentEvent === "dewali" | this.props.currentEvent === "thaipusam" ?
                                                    <>
                                                        <div className={`fes-amount-wrap ${!isDesktop && 'mobile'}`}>
                                                            <p className='fes-ani-amount' style={{ fontSize: "2rem" }}>RM</p>
                                                            <p className='fes-ani-amount mt-3'>{this.state.angpaoAmt}</p>
                                                        </div>
                                                        <img className={`fes-ani-asset ${!isDesktop && 'mobile'}`} src={dewali["dewali.png"]} alt="ketupak open" /> </>
                                                    : ""
                                            }

                                        </>
                                    )
                                }
                                <div className={`ani-bottom-wrapper  ${!isDesktop && 'mobile'} ${this.state.bonusStatus === false && this.props.currentEvent === "dewali" ? 'dewali-margin': this.state.bonusStatus === false && this.props.currentEvent === "thaipusam" ? 'dewali-margin': ''}`}>
                                    {this.state.bonusStatus === false && this.props.currentEvent !== "cny" &&
                                        <button className={`claim-btn ${!isDesktop && 'mobile'}`} onClick={this.openAngpao} >{t("cny.receive-bonus")}</button>
                                    }
                                    <div className='close-button-wrapper'>
                                        {this.state.bonusStatus === false &&
                                            <h5 onClick={(e) => { e.preventDefault(); window.location.href = '/promos'; }} className='mt-3'>{t("cny.details")}</h5>
                                        }
                                        <Icon type="button" className="fes-btn-close" icon="mdi:close" onClick={this.props.toggleHomeEvents} />
                                    </div>
                                </div>
                            </div>
                            {this.props.currentEvent === "dewali" || this.props.currentEvent === "thaipusam" ?
                                <img className={`title-bg lantern-bg ${!isDesktop && 'mobile'}`} src={dewali["dewali_bg.png"]} alt="asset bg" /> : ""
                            }

                        </div>
                        <div className='fes-ani-bg-wrap'>
                            {this.props.currentEvent === "cny" &&
                                <>
                                    <img className={`rain-one ${!isDesktop && 'mobile'}`} src={angpao["rain-1.png"]} alt="rain one" />
                                    <img className={`rain-two ${!isDesktop && 'mobile'}`} src={angpao["rain-2.png"]} alt="rain two" />
                                    <img className={`rain-three ${!isDesktop && 'mobile'}`} src={angpao["rain-3.png"]} alt="rain three" />
                                </>
                            }
                            <div className={`fes-ani-overlay ${!isDesktop && 'mobile'}`}></div>
                        </div>

                    </section>
                ) : ("")
                }

            </Auxiliary>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        claim_angpau_result: state.events.claim_angpau_result
    };
};

const mapDispatchToProps = {
    claimAngpau
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNamespaces("translation")(RayaEvents));