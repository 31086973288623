import React, { Component } from "react";
import { connect } from "react-redux";
// import DomPurify from "../../../components/Sanitize/DomPurify";
import { withNamespaces } from "react-i18next";
// import Skeleton from "react-loading-skeleton";

//Miscellaneous
// import ReactPlayer from "react-player";
// import { LazyLoadImage } from "react-lazy-load-image-component";

//Icons
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { detectCountry } from "../../../hoc/Shared/utility";

let dynamicCountry = detectCountry(window.location.host);

export class ProductOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // currentTab: 1,
      activeTab: 1,
    };
  }

  changeTab = (current, next) => {
    this.setState({
      // currentTab: next + 1,
      changingTab: true,
      activeTab: next + 1,
    });
  };

  goToSlick = (index, tab) => {
    this.slider.slickGoTo(index);
    this.setState({
      activeTab: tab,
    });
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    // let imageLanguage = "";
    // switch (this.props.currentUser.language_code) {
    //   case "zh-CN":
    //     imageLanguage = "-cn";
    //     break;
    //   // case "ms":
    //   //   imageLanguage = "-ms"
    //   //   break;
    //   default:
    //     imageLanguage = "";
    //     break;
    // }

    const image = this.imageImport(
      require.context(
        "../../../assets/images/home",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const productImg = this.imageImport(
      require.context(
        "../../../assets/images/about-us",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const icon = this.imageImport(
      require.context(
        "../../../assets/images/icons/mobile",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );

    const chinese = this.imageImport(
      require.context(
        "../../../assets/images/home/chinese",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const raya = this.imageImport(
      require.context(
        "../../../assets/images/home/raya",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const merdeka = this.imageImport(
      require.context(
        "../../../assets/images/home/merdeka",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const mooncake = this.imageImport(
      require.context(
        "../../../assets/images/home/mooncake",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const halloween = this.imageImport(
      require.context(
        "../../../assets/images/home/halloween",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const christmas = this.imageImport(
      require.context(
        "../../../assets/images/home/christmas",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const uefa = this.imageImport(
      require.context(
        "../../../assets/images/home/uefa",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );


    const anni = this.imageImport(
      require.context(
        "../../../assets/images/home/anni",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );


    const products = [
      {
        id: 1,
        icon: "lc-m",
        activeIcon: "lc-active-m",
        lable: "live-casino",
        name: "lc",
        link: `online-live-games-${dynamicCountry.label}`,
      },
      {
        id: 2,
        icon: "sport-m",
        activeIcon: "sport-active-m",
        lable: "sports",
        name: "sport",
        link: `online-sports-games-${dynamicCountry.label}`,
      },
      {
        id: 3,
        icon: "slot-m",
        activeIcon: "slot-active-m",
        lable: "slots",
        name: "slot",
        link: "slot",
      },
      {
        id: 4,
        icon: "games-m",
        activeIcon: "games-active-m",
        lable: "games",
        name: "games",
        link: "games",
      },
      {
        id: 5,
        icon: "p2p-m",
        activeIcon: "p2p-active-m",
        lable: "p2p",
        name: "p2p",
        link: "p2p",
      },
      {
        id: 6,
        icon: "lottery-m",
        activeIcon: "lottery-active-m",
        lable: "lottery",
        name: "lottery",
        link: "lottery",
      },
    ];

    const settings = {
      slidesToShow: 1,
      adaptiveHeight: false,
      centerMode: true,
      centerPadding: "392px",
      // focusOnSelect: true,
      swipe: false,
      infinite: true,
      arrows: false,
      beforeChange: (current, next) => {
        this.changeTab(current, next);
      },
      afterChange: (current) => this.setState({ activeSlide2: current }),
    };

    return (
      <section
        className={`global-section ${this.props.currentEvent === "cny" ? "cny event" : this.props.currentUser.country_id !== 4 && this.props.currentEvent === "raya" || this.props.currentEvent === "anni" ||this.props.currentEvent === "merdeka" ||  this.props.currentEvent ===  "uefa" ? "event" : ""}`}
      >
        <div className="x-container">
          <div
            className={`section-header ${this.props.currentEvent === "cny" && "cny-header"
              }`}
          >
            <div className="d-flex align-item-center">
              {this.props.currentEvent === "christmas" ? (
                <img
                  src={christmas["title-deco-left.webp"]}
                  alt="header-deco"
                />
              ) : this.props.currentEvent === "cny" ? (
                <img
                  src={chinese["title-deco-left.webp"]}
                  alt="header-deco"
                />
              ) : (
                <></>
              )}
              {this.props.currentEvent === "cny" ? <h2>{t("main-page.product-overview")}</h2> : <h2 className="mb-2">{t("main-page.product-overview")}</h2>}
              {this.props.currentEvent === "christmas" ? (
                <img
                  src={christmas["title-deco-right.webp"]}
                  alt="header-deco"
                />
              ) : this.props.currentEvent === "cny" ? (
                <img
                  src={chinese["title-deco-right.webp"]}
                  alt="header-deco"
                />
              ) : (
                <></>
              )}
            </div>
            <p>{t("main-page.product-overview-remark")}</p>
          </div>
          <ul className="product-ctg">
            {products &&
              products.map((item, index) => (
                <li
                  className={`product-ctg-btn btn ${this.state.activeTab === item.id ? "active" : ""
                    }`}
                  onClick={() => this.goToSlick(index, item.id)}
                  key={index}
                >
                  <img
                    className="ctg-img"
                    src={icon[`${item.icon}.svg`]}
                    alt="mobile-nav-img"
                  />
                  <img
                    className="active-ctg-img"
                    src={icon[`${item.activeIcon}.svg`]}
                    alt="mobile-active-nav-img"
                  />
                  <p>{t(`about-us.${item.lable}`)}</p>
                </li>
              ))}
          </ul>
          <div className="vip-slick product-overview-slick">
            <div className={`slick-container ${this.props.currentEvent === "christmas" || this.props.currentEvent ==="anni" ? "christmas" : ""}`}>
              <Slider ref={(c) => (this.slider = c)} {...settings}>
                {products &&
                  products.map((item, index) => (
                    <Link
                      className="product-overview"
                      to={{ pathname: `/${item.link}` }}
                      key={index}
                    >
                      {
                        this.props.currentEvent === "raya" ? (
                          <>
                            <span className="card-shadow">
                              <img
                                className="product-overview-bg"
                                src={image["product-overview-bg.webp"]}
                                alt="product background"
                              />
                            </span>
                            <img
                              className="product-overview-bg active"
                              src={raya["product-overview-bg.webp"]}
                              alt="product background"
                            />
                          </>
                        ) : this.props.currentEvent === "merdeka" && this.props.currentUser.country_id !== 4 ? (
                          <>
                            <span className="card-shadow">
                              <img
                                className="product-overview-bg"
                                src={image["product-overview-bg.webp"]}
                                alt="product background"
                              />
                            </span>
                            <img
                              className="product-overview-bg active merdeka"
                              src={merdeka["product-overview-bg.webp"]}
                              alt="product background"
                            />
                          </>
                        ) : this.props.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ? (
                          <>
                            <span className="card-shadow">
                              <img
                                className="product-overview-bg"
                                src={image["product-overview-bg.webp"]}
                                alt="product background"
                              />
                            </span>
                            <img
                              className="product-overview-bg active merdeka"
                              src={mooncake["product-overview-bg.webp"]}
                              alt="product background"
                            />
                          </>
                        ) : this.props.currentEvent === "halloween" ? (
                          <>
                            <span className="card-shadow">
                              <img
                                className="product-overview-bg"
                                src={image["product-overview-bg.webp"]}
                                alt="product background"
                              />
                            </span>
                            <img
                              className="product-overview-bg active halloween"
                              src={halloween["product-overview-bg.webp"]}
                              alt="product background"
                            />
                          </>
                        ) : this.props.currentEvent === "cny" ? (
                          <>
                            <span className="card-shadow">
                              <img
                                className="product-overview-bg"
                                src={image["product-overview-bg.webp"]}
                                alt="product background"
                              />
                            </span>
                            <img
                              className="product-overview-bg active"
                              src={chinese["product-overview-bg.webp"]}
                              alt="product background"
                            />
                          </>
                        ) : this.props.currentEvent === "christmas" ? (
                          <>
                            <span className="card-shadow">
                              <img
                                className="product-overview-bg"
                                src={image["product-overview-bg.webp"]}
                                alt="product background"
                              />
                            </span>
                          </>
                        ) : this.props.currentEvent === "uefa" ? (
                          <>
                            <span className="card-shadow">
                              <img
                                className="product-overview-bg"
                                src={image["product-overview-bg.webp"]}
                                alt="product background"
                              />
                            </span>
                            <img
                              className="product-overview-bg active merdeka"
                              src={uefa["product-overview-bg.webp"]}
                              alt="product background"
                            />
                          </>
                        ) : this.props.currentEvent === "anni" ? (
                          <>
                            <span className="card-shadow">
                              <img
                                className="product-overview-bg"
                                src={image["product-overview-bg.webp"]}
                                alt="product background"
                              />
                            </span>
                            <img
                              className="product-overview-bg active anni"
                              src={anni["product-overview-bg.webp"]}
                              alt="product background"
                            />
                          </>
                        ) : (
                          <>
                            <span className="card-shadow">
                              <img
                                className="product-overview-bg"
                                src={image["product-overview-bg.webp"]}
                                alt="product background"
                              />
                            </span>
                          </>
                        )
                      }
                      {/* <>
                        <span className="card-shadow">
                          <img
                            className="product-overview-bg"
                            src={image["product-overview-bg.webp"]}
                            alt="product background"
                          />
                        </span>
                        {
                          this.props.currentEvent === "raya" ? (
                            <img
                              className="product-overview-bg active"
                              src={raya["product-overview-bg.webp"]}
                              alt="product background"
                            />
                          ) : (
                            <span className="card-shadow">
                              <img
                                className="product-overview-bg active"
                                src={image["product-overview-bg.webp"]}
                                alt="product background"
                              />
                            </span>

                          )
                        }
                      </> */}

                      <span className="product-overview-img">
                        <img
                          src={productImg[`${item.name}-img.webp`]}
                          alt={item.id}
                        />
                      </span>
                    </Link>
                  ))}
              </Slider>
              <div className="w-100 d-flex justify-content-center">
                {this.props.currentEvent === "christmas" ?
                  <>
                    <img
                      className="product-platform christmas"
                      src={christmas["platform.webp"]}
                      alt="product platform"
                    />
                    <img
                      className="platform-deco"
                      src={christmas["snowman.webp"]}
                      alt="wave"
                    />
                  </> : this.props.currentEvent === "cny" ? (
                    <>
                      <img
                        className="product-platform "
                        src={chinese["platform.webp"]}
                        alt="product platform"
                      />
                    </>
                  ) :
                    <>
                      <img
                        className="product-platform"
                        src={image["platform.webp"]}
                        alt="product platform"
                      />
                      <img
                        className="platform-wave"
                        src={image["wave.webp"]}
                        alt="wave"
                      /> </>
                }

                {
                  this.props.currentEvent === "raya" && (
                    <>
                      <img
                        className="deco-1"
                        src={raya["content-deco-2.webp"]}
                        alt="decoration"
                      />
                      <img
                        className="deco-2"
                        src={raya["content-deco-3.webp"]}
                        alt="decoration"
                      />
                    </>
                  )
                }
                {
                  this.props.currentEvent === "halloween" && (
                    <>
                      <img
                        className="deco-1 halloween"
                        src={halloween["content-deco-1.webp"]}
                        alt="decoration"
                      />
                      <img
                        className="deco-2 halloween"
                        src={halloween["content-deco-2.webp"]}
                        alt="decoration"
                      />
                    </>
                  )
                }
              </div>

              <div className="slick-arrow">
                <div
                  className="slick-arrow-btn prev"
                  onClick={() => this.slider.slickPrev()}
                >
                  <Icon icon="uil:angle-left-b" />
                </div>
                <div
                  className="slick-arrow-btn next"
                  onClick={() => this.slider.slickNext()}
                >
                  <Icon icon="uil:angle-right-b" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.data,
  };
};

export default connect(mapStateToProps)(
  withNamespaces("translation")(ProductOverview)
);
